<template lang="pug">
Layout
  PageHeader(:title="title", :items="items")
  .row
    .col-lg-12.pl-3
      b-card-body
        .search-box.col-lg-4.mb-3
          b-form-input.form-control#tip(type="search", placeholder="搜尋影音名稱", v-model="search_data.name" @keyup.enter="getFileList()")
          i.ml-2.fas.fa-search
        .radio-group.mt-2.ml-2.mb-3
          label.mb-0 檔案類型
          .radio-group.col-lg-8.ml-2
            b-form-radio-group#radio-type(v-model="search_data.type", text-field="label", :options="type_options", name="type-options")
        .radio-group.mt-2.ml-2.mb-3
          label.mb-0 檔案來源
          .radio-group.col-lg-8.ml-2
            b-form-radio-group#radio-source(v-model="search_data.source", text-field="label", :options="source_options", name="source-options")
        .editer-box.col-lg-4.mb-3
          label.mb-0 上傳人員
          br
          multiselect(
            v-model="currentManager",
            :options="managers",
            placeholder="請選擇人員",
            label="name",
            track-by="id",
            @search-change="searchManagerList",
            select-label=""
          )

        .upload-box.col-lg-4.mt-3.ml-0
          b-button.width-lg(variant="primary" @click="reSetUploadFiles") ＋ 上傳影音檔案
          p.mb-1 (建議圖片檔案 4MB 內, 影片檔案 3GB 內)
        .row
          .sort-group.col-auto.mt-3.ml-2
            b-button.width-lg.d-flex.align-items-center(:variant="search_data.sort_by === 1 ? 'secondary' : 'outline-secondary'", @click="switchSortBy(1)")
              i.bx.bx-list-ol
              i.fe-arrow-down(v-if="search_data.sort_method === 'ASC'")
              i.fe-arrow-up(v-if="search_data.sort_method === 'DESC'")
              span 按更新時間排序
          .sort-group.col-auto.mt-3.ml-0
            b-button.width-lg.d-flex.align-items-center(:variant="search_data.sort_by === 2 ? 'secondary' : 'outline-secondary'", @click="switchSortBy(2)")
              i.bx.bx-list-ol
              i.fe-arrow-down(v-if="search_data.sort_method === 'ASC'")
              i.fe-arrow-up(v-if="search_data.sort_method === 'DESC'")
              span 按提供來源排序
    .row.col-12
      .col-lg-10.ml-4
        b-card-body.card-group
          .col-lg-2.p-2(v-for="(file,index) in file_list", :key="index")
            b-card.mb-1
              img.card-img.mt-2(v-if="file.type === 1",:src="file.url")
              img.card-img.mt-2(v-if="file.type === 2",src="@/assets/images/VideoIcon.png")
              b-button.card-title.pl-2.pr-2(size="sm" variant="warning" v-if="file.source === 1" ) 外部授權
              b-button.card-title.pl-2.pr-2(size="sm" variant="info" v-if="file.source === 2" ) 中天新聞
              b-button.card-title.pl-2.pr-2(size="sm" variant="info" v-if="file.source === 3" ) 網友投稿
              b-button.card-title.pl-2.pr-2(size="sm" variant="warning" v-if="file.source === 4" ) 開票地圖專用
              i.fe-edit.media-edit-button(@click="editMedia(file)")
              h5.text-center.mt-2.mb-2 {{file.file_name}}
              p.text-center {{ file.upload_at }}
              b-button(@click="$root.common.copyCuponCode(file.url, '檔案連結')") 複製連結
      .col-lg-10.row.ml-4.mt-4
        .col-6
          //- div(id="tickets-table_length", class="dataTables_length")
          //-   label.d-inline-flex.align-items-center Show
          //-     b-form-select.mr-1.ml-1(v-model="perPage", size="sm", :options="pageOptions") 
          //-     | entries
        .col-6
          div(class="dataTables_paginate paging_simple_numbers float-right")
            ul.pagination.pagination-rounded.mb-0
              b-pagination(v-model="search_data.page", :total-rows="totalRows", :per-page="perPage")

  b-modal#modal-upload(
    ref="mediaEditorModal"
    title="影音檔案編輯", 
    title-class="font-18", 
    body-class="p-4" 
    size="lg"
    hide-footer
  )
    .row.upload-files
      .col-12.text-center.pt-1
        img.editor-media-image(v-if="currentEditfile.type === 1" :src="currentEditfile.url" style="object-fit:cover; border-radius: 5px;")
        video.editor-media-image(v-if="currentEditfile.type === 2",controls,width="650",height="400")
          source(:src="currentEditfile.url")
      .col-12.upload-file-detail
        hr
        .row
          .radio-group.col-lg-12.ml-2
            b-form-radio-group#radio-group-1(
              v-model="currentEditfile.source", 
              :options="radio_options3", 
              text-field="label"
              :name="'file-source-'+currentEditfile.id"
            )
        //- .mt-3
          .form-group.ml-2
            .row
              .col-6
                select.form-control
                  option 選擇浮水印位置
                  option(value="0") 不加上浮水印
                  option(value="1") 左上
                  option(value="2") 左下
                  option(value="3") 右上
                  option(value="4") 右下
                  option(value="5") 置中
              .col-6
                select.form-control
                  option 選擇圖片轉向
                  option(value="0") 不轉向
                  option(value="1") 向左90度
                  option(value="2") 向左180度
                  option(value="3") 向右90度
                  option(value="4") 向右180度
        .mt-3
          .form-group.ml-2
            input.form-control(
              v-model="currentEditfile.file_name", 
              type="text"
              placeholder="請輸入影音標題"
            )
        .mt-0
          .form-group.mb-3.ml-2
            textarea.form-control(
              v-model="currentEditfile.des", 
              type="text" 
              rows="3" 
              placeholder="請輸入影音描述，此描述僅做檢索不顯示於內頁"
            )
    .check-buttons.text-center
      b-button.width-lg.mr-2(variant="secondary", @click="$root.common.copyCuponCode(currentEditfile.url, '媒體連結')") 複製連結
      b-button.width-lg(variant="primary", @click="putMedia(currentEditfile)") 更新檔案

  b-modal#modal-upload(
    ref="mediaUploadModal"
    title="上傳影音", 
    title-class="font-18", 
    body-class="p-4" 
    size="lg"
    hide-footer
  )
    .upload-infomation
      b-progress(:max="100",v-if="uploadProgress > 0")
        b-progress-bar(:value="uploadProgress", variant="info")
    vue-dropzone#dropzone.mt-3(
      ref="myVueDropzone", 
      :use-custom-slot="true", 
      :options="dropzoneOptions"
      @vdropzone-upload-progress="dropzoneUploadProgress" 
      @vdropzone-file-added="dropzoneFileAdded"
      @vdropzone-removed-file="dropzoneFileRemove"
      v-on:vdropzone-success="uploadSuccess"
    )
      .dz-message.needsclick
        i.h1.text-muted.ri-upload-cloud-2-line
        h4 點擊即可上傳或拖曳檔案 
        h4 PNG、JPG或GIF（最大4MB），MP4(最大3GB)
    .mt-3
    .system-progress-status
      .text 目前作業: 
        b(v-if="fileUploadSuccess") 上傳已完成
        b(v-else-if="uploadProgress === 0") 等待上傳檔案中
        b(v-else-if="uploadProgress > 0 && uploadProgress < 100") 正在投遞檔案中
        b(v-else-if="uploadProgress >= 100") 服務器正在處理檔案
    .mt-3
    .row.upload-files.mt-3(v-for="(file, index) in files")
      .col-3.text-center.pt-4
        img.avatar-xl(:src="file.url" style="object-fit:cover; border-radius: 5px;")
      .col-9.upload-file-detail
        .row
          .radio-group.col-lg-12.ml-2
            b-form-radio-group#radio-group-1(
              v-model="file.source", 
              :options="radio_options3", 
              text-field="label"
              :name="'file-source-'+file.id"
            )
        //- .mt-3
          .form-group.ml-2
            .row
              .col-6
                select.form-control
                  option 選擇浮水印位置
                  option(value="0") 不加上浮水印
                  option(value="1") 左上
                  option(value="2") 左下
                  option(value="3") 右上
                  option(value="4") 右下
                  option(value="5") 置中
              .col-6
                select.form-control
                  option 選擇圖片轉向
                  option(value="0") 不轉向
                  option(value="1") 向左90度
                  option(value="2") 向左180度
                  option(value="3") 向右90度
                  option(value="4") 向右180度
        .mt-3
          .form-group.ml-2
            input.form-control(
              v-model="file.file_name", 
              type="text"
              placeholder="請輸入影音標題"
            )
        .mt-0
          .form-group.mb-3.ml-2
            textarea.form-control(
              v-model="file.des", 
              type="text" 
              rows="3" 
              placeholder="請輸入影音描述，此描述僅做檢索不顯示於內頁"
            )
    .check-buttons.text-center(v-if="files.length")
      b-button.width-lg(v-if="fileUploadSuccess" variant="primary",@click="putFilesData") 更新檔案
      
</template>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/Page-header";
import vue2Dropzone from "vue2-dropzone";
import Multiselect from "vue-multiselect";

/**
 * Starter component
 */
export default {
  name: 'MediaLibrary',
  head () {
    return {
      title: `${this.title} | Minton - Nuxtjs Responsive Admin Dashboard Template`,
    };
  },
  data () {
    return {
      title: "影音庫",
      items: [
        {
          text: "上稿管理",
          href: "/",
        },
        {
          text: "影音庫",
          active: true,
        }
      ],
      search_data: {
        name: '',
        type: 0,
        source: 0,
        sort_by: 1,
        sort_method: 'DESC',
        page: 1,
        per_page: 24
      },
      dropzoneTotalFilesize: 0,
      dropzoneUploadedFilesize: 0,
      dropzoneCurrentUpload: 0,
      fileUploadProgress: 0,
      fileUploadSuccess: false,
      file_list: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],

      value: '',
      selected: '',
      managers: [],
      currentManager: false,
      type_options: [
        { label: "顯示全部", value: 0 },
        { label: "影片", value: 2 },
        { label: "圖片", value: 1 },
      ],
      source_options: [
        { label: "顯示全部", value: 0 },
        { label: "外部授權", value: 1 },
        { label: "中天新聞", value: 2 },
        { label: "網友投稿", value: 3 },
        { label: "開票地圖專用", value: 4 }
      ],
      radio_options3: [
        { label: "外部授權", value: 1 },
        { label: "中天新聞", value: 2 },
        { label: "網友投稿", value: 3 },
        { label: "開票地圖專用", value: 4 }
      ],
      dropzoneOptions: {
        autoProcessQueue: true,
        url: this.$root.upload_uri + "/media/create/files/default",
        parallelUploads: 20,
        maxFiles: 10,
        timeout: 0,
        uploadMultiple: true,
        thumbnailWidth: 150,
        maxFilesize: 3221225472,
        paramName: 'files',
        acceptedFiles: "image/jpeg,image/png,image/gif,video/*",
        headers: { "Authorization": "Bearer " + this.$root.common.getCookie('api-token') },
        previewTemplate: this.template(),
      },
      files: [],
      currentEditfile: {
        url: '',
        source: '',
        id: '',
        file_name: '',
        des: '',
      }
    };
  },
  computed: {},
  watch: {
    'search_data.source' () {
      this.getFileList()
    },
    'search_data.type' () {
      this.getFileList()
    },
    'search_data.page' () {
      this.getFileList()
    },
  },
  mounted () {
    this.getFileList()
  },
  computed: {
    uploadProgress () {
      return Math.round((this.dropzoneUploadedFilesize + this.dropzoneCurrentUpload) / this.dropzoneTotalFilesize * 100);
    }
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    vueDropzone: vue2Dropzone,
  },
  methods: {
    template: function () {
      return ` <div class="dropzone-previews mt-3">
            <div class="card mt-1 mb-0 shadow-none border">
                <div class="p-2">
                    <div class="row align-items-center">
                        <div class="col-auto">
                            <img data-dz-thumbnail src="#" class="avatar-sm rounded bg-light" alt="">
                        </div>
                        <div class="col pl-0">
                            <a href="javascript:void(0);" class="text-muted font-weight-bold" data-dz-name></a>
                            <p class="mb-0" data-dz-size></p>
                        </div>
                        <div class="col-auto">
                            <!-- Button -->
                            <a href="" class="btn btn-link btn-lg text-muted" data-dz-remove>
                                <i class="fe-x"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        `;
    },
    searchManagerList (_text) {
      let vm = this
      console.log("=> 管理員搜尋:", _text)
      this.$root.apis.getFastManagerList({
        name: _text,
        per_page: 100
      }, function (_response) {
        vm.managers = _response.body.data.items
      })
    },
    // 獲得檔案列表
    getFileList () {
      this.file_list = []
      this.$root.apis.getFileList(this.search_data, this.getFileListSuccess)
    },
    getFileListSuccess (_response) {
      this.file_list = _response.body.files
      this.totalRows = _response.body.total
      this.currentPage = _response.body.page
      this.perPage = _response.body.per_page
    },
    switchSortMethods () {
      if (this.search_data.sort_method === 'DESC') {
        this.search_data.sort_method = 'ASC'
      } else {
        this.search_data.sort_method = 'DESC'
      }
    },
    switchSortBy (_type) {
      if (_type === this.search_data.sort_by) {
        this.switchSortMethods()
      }
      this.search_data.sort_by = _type
      this.getFileList()
    },
    // 預備上傳檔案
    reSetUploadFiles () {
      // 重置進度條
      this.dropzoneTotalFilesize = 0;
      this.dropzoneUploadedFilesize = 0;
      this.dropzoneCurrentUpload = 0;

      // 重置檔案清單
      this.files = []

      // 開啟上傳介面
      this.$refs.mediaUploadModal.show()
    },
    // 增加上傳檔案時
    async dropzoneFileAdded (_file) {
      this.fileUploadSuccess = false
      this.dropzoneTotalFilesize += _file.size;
      console.log('=> 需上傳檔案總大小:', this.dropzoneTotalFilesize)

      console.log('=> 增加處理檔案:', _file)
      // console.log('--> 圖片網址:', _file.dataURL);

      let searchFileStatus = false
      await this.files.forEach(function (file) {
        if (file.id === _file.name) {
          searchFileStatus = true
        }
      })

      if (!searchFileStatus) {
        // console.log('--> 圖片網址:', _file.dataURL);
        await this.files.push({
          url: _file.dataURL,
          source: 1,
          id: _file.name,
          source_name: _file.name,
          file_name: _file.name,
          des: _file.name
        })
      }

      await setTimeout(async () => {
        await this.processFileContent(this.$refs.myVueDropzone.getAcceptedFiles())
      }, 1000)
    },
    async dropzoneFileRemove (_file) {
      let vm = this
      console.log('=> 移除處理檔案:', _file)
      let searchFileStatus = false
      await this.files.forEach(function (file, index) {
        if (file.id === _file.name) {
          searchFileStatus = index
        }
      })
      if (searchFileStatus) {
        this.files.splice(searchFileStatus, 1)
      }
    },
    async processFileContent (_files) {
      let vm = this
      console.log('--> 需處理檔案:', _files)
      await _files.forEach(async function (_file) {
        let searchFileStatus = false
        await vm.files.forEach(function (file, index) {
          if (file.id === _file.name && _file.dataURL) {
            vm.files[index].url = _file.dataURL
            // console.log('--> 替換圖片網址:', _file.dataURL);
          }
        })
      })
    },
    // 檔案上傳進度
    dropzoneUploadProgress (file, totalBytes, totalBytesSent) {
      this.dropzoneCurrentUpload = totalBytesSent; // write totalBytes to dropzoneCurrentUpload
      console.log('=> 已上傳檔案總大小:', this.dropzoneCurrentUpload)
      if (file.size <= totalBytesSent) {
        this.dropzoneCurrentUpload = 0; // reset current upload bytes counter
        this.dropzoneUploadedFilesize += totalBytesSent; // add finished file to total upload
      }
    },
    // 檔案上傳完成
    async uploadSuccess (file, _response) {

      if (_response.code !== '0000') {
        return
      }

      console.log('=> 檔案上傳完成 file:', file)
      console.log('=> 檔案上傳完成 _response:', _response)

      // 檔案上傳完成
      let sourceFileName = file.name
      console.log('=> 檔案原始名稱:', sourceFileName)

      let mediaFileID
      await _response.data.forEach(async function (_media) {
        if (_media.file_name === sourceFileName) {
          mediaFileID = await _media.id
        }
      })
      console.log('=> 檔案存儲ID:', mediaFileID)

      // 處理檔案資訊更新
      let vm = this
      await this.files.forEach(async function (_file, _fileIndex) {
        if (_file.source_name === sourceFileName) {
          vm.files[_fileIndex].id = await mediaFileID
        }
      })

      this.fileUploadSuccess = true

      // 處理檔案更新送出作業
      // await setTimeout(async () => {
      //   await this.putFilesData()
      // }, 5000);
    },
    async startUploadFiles () {
      this.$refs.myVueDropzone.processQueue()
    },
    // 請求更新檔案資訊
    async putFilesData () {
      let vm = this
      // 逐一將檔案資訊送至後端更新
      await this.files.forEach(async function (_file) {
        await vm.$root.apis.putFileDetail(_file.id, _file, function (_response) { })
      })
      // 重新獲取檔案清單
      await setTimeout(async () => {
        await this.getFileList()
      }, 1500);
      // 跳出通知
      await vm.$root.common.showSingleNotify("檔案資訊更新成功！")
      // 關閉 modal
      await this.$refs.mediaUploadModal.hide()
    },
    // 請求編輯檔案
    async editMedia (_currentEditfile) {
      // 針對影音進行處理
      if (_currentEditfile.type === 2) {
        _currentEditfile.url = _currentEditfile.url.replace('/hls', '')
        _currentEditfile.url = _currentEditfile.url.replace('.m3u8', '')
        console.log('--> 網址處理結果:', _currentEditfile.url)
      }
      this.currentEditfile = _currentEditfile
      this.$refs.mediaEditorModal.show()
    },
    // 請求更新編輯的檔案資訊
    async putMedia (_file) {
      // 關閉 modal
      this.$refs.mediaEditorModal.hide()
      // 請求更新檔案設定
      this.$root.apis.putFileDetail(_file.id, _file, function (_response) { })
    }
  }
};
</script>
<style lang="sass" scoped>
  img.editor-media-image
    max-width: 300px
    max-height: 200px
  .search-box
    width: 25rem
    position: relative
    #tip
      padding: 1.5rem 2rem
      background-repeat: no-repeat
      background-position: 10px 7px
      line-height: 4rem
    .fa-search
      position: absolute
      top: 39%
  .editer-box
    width: 25rem
    position: relative
  .radio-group
    display: flex
    align-items: center
  .sort-group
    i
      font-size: 1.3rem
  .card-group
    position: relative
    align-items: stretch
    padding: 0
    width: 100%
    .media-edit-button
      position: absolute
      right: 5px
      top: 5px
    .card
      height: 100%
      transition-duration: .3s
      cursor: pointer
      min-width: 169px
      &:hover
        transform: scale(1.1)
        box-shadow: 0 0 7px #ddd
    .card-body
      text-align: center
      padding: 1rem
      display: flex
      flex-direction: column

    .card-img
      // padding: 1.5rem 1.5rem
      width: 72px
      height: 72px
      border-radius: 5px
      margin: 0 auto
      object-fit: cover
      img
        width: 100%
        height: 100%
        object-fit: cover
        border-radius: 6px
    .card-title
      position: absolute
      top: 10px
      left: 10px
      border-radius: 50px
    h5
      overflow: hidden
      text-overflow: ellipsis
      display: -webkit-box
      -webkit-line-clamp: 2
      -webkit-box-orient: vertical
      line-height: 140%
      height: 16px
      max-height: 38px
      min-height: 38px
      flex: 1
    p 
      font-size: 12px
      margin-bottom: 0
    .text-group
      text-align: center
  .dropzone-previews
    height: 170px
    .preview-img
      width: 100%
      height: 100%
      max-height: 170px
      object-fit: cover
    .img-del-btn
      position: absolute
      right: -15px
      top: -15px
      background: #1e40b0
      border-radius: 50%
      padding: 0
      height: 30px
      width: 30px
      line-height: 30px
      cursor: pointer
      i
        color: #fff
        cursor: pointer
  .upload-file-detail
    input.form-control
      // width: 100%
</style>
